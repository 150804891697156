import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../assets/ailox_logo.png';



export default function FooterBlock() {


    return (
        <Container fluid className='p-3' id="footer">
            <Container fluid='md' className='mt-5 mb-5'>
                <Row>
                    <Col xs={12} md={8}>
                        <Link to="/">
                            <Image src={logo} width={100} />
                        </Link>
                        <p className='small' style={{maxWidth:500}}>
                            Indirizzo, Genova, Italy | VAT, tax code, and number of registration with the Genova Company Register 00000000000 | REA number AA 0000000
                        </p>
                    </Col>
                    <Col xs={6} md={2}>
                        <h4 className='mt-4'>Menù</h4>
                        <div className='small pt-3'>
                            <li><Link to="/" className='link-light'>Homepage</Link></li>
                            <li><Link to="/about" className='link-light'>About</Link></li>
                            <li><Link to="/contact" className='link-light'>Contatti</Link></li>
                        </div>
                    </Col>
                    <Col xs={6} md={2}>
                        <h4 className='mt-4'>Legal</h4>
                        <div className='small pt-3'>
                            <li><Link to="/cookie_policy" className='link-light'>Cookie Policy</Link></li>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};