import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, Image, Row, Col } from 'react-bootstrap';
import logo from '../../assets/ailox_logo.png';



export default function HeaderBlock() {

    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        //var element = document.getElementById("app-container");
        //element.setAttribute("data-page", location.pathname);
    }, [location]);

    
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);



    return (
        <Container fluid='md' id="header">
            <Row className='align-items-center'>
                <Col xs={12} md={6}>
                    <Container fluid={'lg'} className='ps-5 pe-5 text-dark overflow-hidden text-center text-md-start'>
                        <Link to="/">
                            <span id='header-logo'>
                                <Image src={logo} width={90} />
                            </span>
                        </Link>
                    </Container>
                </Col>
                <Col xs={12} md={6}>
                    <Container fluid={'lg'}>
                        <Navbar id='header-navbar' className='p-0 justify-content-end'>
                            <Nav>
                                <Nav.Link to="/" as={Link}>
                                    Homepage
                                </Nav.Link>
                                <Nav.Link to="/about" as={Link}>
                                    About
                                </Nav.Link>
                                <Nav.Link to="/contact" as={Link}>
                                    Contatti
                                </Nav.Link>
                                {/*
                                <Nav.Link to="/blog/1" as={Link}>
                                    Blog
                                </Nav.Link>
                                */}
                            </Nav>
                        </Navbar>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};