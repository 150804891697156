import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image, Row, Col } from 'react-bootstrap';
import TextTransition, { presets } from "react-text-transition";
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import bg from '../assets/pexels-darshan394-1003868.jpg';
import terminal from '../assets/terminal-photo2.jpg';
import environment from '../assets/environment.svg';
import spediporto from '../assets/partner/spediporto.png';
import tie from '../assets/partner/tie.png';
import pqs from '../assets/partner/PQS.png';
import csp from '../assets/partner/csp.png';
import vgm from '../assets/partner/vgm.png';


export default function Home() {

    /* link per icone: https://www.svgrepo.com/ */

    const titles = [
        "sognatori",
        "visionari",
        "innovatori",
        "creativi"
    ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() =>
            setIndex(index => index + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);


    return (
        <>
            <Helmet>
                <title>Ailox</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='pt-5 pb-5 ps-0 pe-0 d-flex justify-content-center align-items-center flex-column' style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', minHeight: '100vh' }}>
                <Container fluid="md" className='mt-5 text-start text-white p-5'>
                    <Parallax speed={-8}>
                        <h1 style={{ maxWidth: 500 }}>
                            Ailox, la rivoluzione tech nel mondo della logistica e dei trasporti
                        </h1>
                    </Parallax>

                    <Row className='mt-5'>
                        <Col xs={12} lg={8}>
                            <Parallax speed={5}>
                                <div className='mt-5'>
                                    <h2>
                                        Noi siamo: <span>&nbsp;
                                            <TextTransition inline springConfig={presets.default}>
                                                {titles[index % titles.length]}
                                            </TextTransition>
                                        </span>
                                    </h2>
                                    <p>
                                        Offriamo consulenza IT avanzata a tutto il mondo della logistica.
                                        <br />
                                        Ci piace semplificare, ottimizzare e creare nuove soluzioni volte ad efficientare il mondo dei trasporti.
                                    </p>
                                    <p className='mt-5'>
                                        <Link to="/about">
                                            <span className='button-52'>About us</span>
                                        </Link>
                                    </p>
                                </div>

                            </Parallax>
                        </Col>
                        <Col xs={12} lg={4} className='pt-5'>

                        </Col>
                    </Row>

                </Container>
            </Container>

            {/*
            <Container fluid className='pt-5' style={{ backgroundColor: '#fff' }}>
                <Container fluid="md" className='mt-5 text-start p-5'>

                    <Row className='align-items-center mb-5'>
                        <Col xs={12} lg={4} className='text-center'>
                            <Image className='mb-4' src={environment} height={240} style={{ maxWidth: '90%' }} />
                        </Col>
                        <Col xs={12} lg={8} className='text-start'>
                            <h2>Environment</h2>
                            <p className='fw-light' style={{ maxWidth: 600 }}>
                                Abbiamo molto a cuore il nostro pianeta.<br />
                                Abbiamo sviluppato un portale capace di calcolare le emissioni di CO₂ di ogni viaggio.
                                Utilizzando campi noti come il peso del container, del veicolo, il tipo di mezzo, carburante e la distanza calcoliamo e certifichiamo con
                                estrema precisione il Kg di CO₂ prodotti durante il viaggio.<br />
                                Abbiamo partner importanti che offrono servizi di compensazione alle emissioni.
                            </p>
                        </Col>
                    </Row>


                    <ParallaxBanner style={{ height: '80vh' }}
                        layers={[
                            { image: terminal, speed: -25 },
                            {
                                speed: -15,
                                children: (
                                    <div className="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center align-items-center flex-column">

                                    </div>
                                ),
                            },
                        ]}
                        className="aspect-[2/1]"
                    />


                    <Row className='align-items-start mt-5 mb-5'>
                        <Col xs={12} lg={6} className='text-start mb-4'>
                            <p className='small' style={{color: 'rgb(255, 154, 80)'}}><b>Logistica</b></p>
                            <p className='fw-light' style={{ maxWidth: 400 }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </Col>
                        <Col xs={12} lg={6} className='text-start mb-4'>
                            <p className='small' style={{color: 'rgb(255, 154, 80)'}}><b>Terminal</b></p>
                            <p className='fw-light' style={{ maxWidth: 400 }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </Col>
                    </Row>

                </Container>
            </Container>
            */}


            <Container fluid className='pt-5' style={{ backgroundColor: 'transparent' }}>
                <Container fluid="md" className='mt-5 text-start p-5 text-white'>
                    <h2>Lavoriamo con:</h2>
                    
                    <Row className='mt-5 mb-5'>
                        <Col xs={6} md={4} lg={3} className='mb-4'>
                            <span className='bg-white d-flex justify-content-center align-items-center' style={{height: 140, width: 140, borderRadius: 160}}>
                                <Image src={spediporto} width={'80%'} />
                            </span>
                        </Col>
                        <Col xs={6} md={4} lg={3} className='mb-4'>
                            <span className='bg-white d-flex justify-content-center align-items-center' style={{height: 140, width: 140, borderRadius: 160}}>
                                <Image src={csp} width={'80%'} />
                            </span>
                        </Col>
                        <Col xs={6} md={4} lg={3} className='mb-4'>
                            <span className='bg-white d-flex justify-content-center align-items-center' style={{height: 140, width: 140, borderRadius: 160}}>
                                <Image src={pqs} width={'80%'} />
                            </span>
                        </Col>
                        <Col xs={6} md={4} lg={3} className='mb-4'>
                            <span className='bg-white d-flex justify-content-center align-items-center' style={{height: 140, width: 140, borderRadius: 160}}>
                                <Image src={tie} width={'80%'} />
                            </span>
                        </Col>
                        <Col xs={6} md={4} lg={3} className='mb-4'>
                            <span className='bg-white d-flex justify-content-center align-items-center' style={{height: 140, width: 140, borderRadius: 160}}>
                                <Image src={vgm} width={'80%'} />
                            </span>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )

};