import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import notfound from '../assets/404.webp';


export default function NotFound() {

    return (
        <>
            <Helmet>
                <title>Page Not Found - We Bocs</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid id='header-space'></Container>


            <Container fluid className='mt-5 mb-5'>
                <Container fluid="md" className='p-5 text-white text-center'>
                    <h1>Errore 404</h1>
                    <p className='lead'>La pagina che stai cercando non esiste o è stata permanentmenete spostata.</p>

                    <p className='mt-5'>
                        <Link to="/">
                            <span className='button-52'>Vai alla homepage</span>
                        </Link>
                    </p>

                    <Image src={notfound} className="mt-5" width={'90%'} style={{maxWidth:600}} />
                </Container>
            </Container>

        </>
    );
};