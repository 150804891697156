import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';


export default function Contact() {

    return (
        <>
            <Helmet>
                <title>Contaattaci - Ailox</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid id='header-space'></Container>
            

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5 mb-5'>
                    <Col md={1}></Col>
                    <Col md={5}>
                        <h1>Entra in contatto con noi!</h1>
                        <p className='lead'>
                            Puoi trovarci a Genova, in via Roma.<br/>
                            Saremo felici di conoscerti.<br/>
                            Crediamo nella contaminazione positiva derivata dalla condivisione delle idee.
                        </p>
                        <p className='lead'>
                            Se preferisci puoi scriverci al seguente indirizzo mail:<br/>
                            <u>info@ailox.it</u>
                        </p>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                        
                    </Col>
                </Row>
            </Container>
        </>
    );
};