import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';


export default function CookiePolicy() {

    return (
        <>
            <Helmet>
                <title>Cookie Policy</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid id='header-space'></Container>


            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={2}></Col>
                    <Col md={6}>
                        <h1>Cookie Policy</h1>
                    </Col>
                </Row>

                <Row className='text-start mt-5 text-white'>
                    <Col md={2}></Col>
                    <Col md={6}>
                        <p>In che modo vengono utilizzati i cookie e altre tecnologie analoghe sui nostri siti web.</p>
                        <p>I cookie sono piccoli <em>file</em> di testo inviati al Vostro computer quando Voi visitate i nostri siti web. Utilizziamo i cookie per le finalit&agrave; summenzionate e in conformit&agrave; alla presente Informativa sulla privacy.</p>
                        <p>Non utilizziamo i cookie per seguire i singoli visitatori n&eacute; per identificarli, ma piuttosto per ottenere informazioni utili sulle modalit&agrave; di utilizzo dei nostri siti web cos&igrave; da poter continuare a migliorarli per i nostri utenti. I dati personali generati mediante cookie sono raccolti tramite pseudonimizzazione e Voi godete del diritto di opporVi al trattamento di tali dati, secondo quanto indicato di seguito.</p>
                        <p>Utilizziamo i cookie su questo Sito web al fine di:</p>
                        <ul>
                            <li>indirizzarVi verso le sezioni pertinenti del Sito web;</li>
                            <li>garantire che il Sito web abbia un aspetto coerente nei diversi browser e dispositivi;</li>
                            <li>consentire il funzionamento di aree complesse del Sito web; e</li>
                            <li>tenere traccia dei dati statistici anonimizzati e aggregati relativi alle visite al Sito web per poterne migliorare le performance.</li>
                        </ul>
                        <p>&nbsp;</p>
                        <p>A tale scopo, potremmo installare cookie che raccolgano dati quali il nome del dominio dell&rsquo;utente, il provider di servizi Internet, il sistema operativo e la data e l&rsquo;orario di accesso.</p>
                        <p>Di seguito vengono spiegate le tipologie generiche di cookie che utilizziamo e le loro finalit&agrave;.</p>
                        <p>&nbsp;</p>
                        <p><strong>Tipologie di cookie e finalit&agrave;</strong></p>
                        <ul>
                            <li>cookie di prima parte: cookie installati dal sito web che l&rsquo;utente visita (il sito web visualizzato nella finestra URL);</li>
                            <li>cookie di sessione: cookie che scadono al termine della sessione del browser (hanno inizio nel momento in cui l&rsquo;utente apre la finestra del browser e terminano quando l&rsquo;utente esce dal browser);</li>
                            <li>cookie persistenti: cookie che &ldquo;persistono&rdquo; nel dispositivo dopo il termine della sessione del browser e pertanto consentono di memorizzare le preferenze o le azioni dell&rsquo;utente per la sua visita successiva al sito web.</li>
                        </ul>
                        <p>La maggior parte dei browser del web consente di controllare gran parte dei cookie mediante le impostazioni del browser. L&rsquo;utente pu&ograve; impostare il browser in modo da ottenere una notifica ogniqualvolta riceva un cookie; questo gli permetter&agrave; di decidere se accettarlo o meno. Tuttavia, qualora l&rsquo;utente non accettasse un cookie, potrebbe non essere in grado di utilizzare tutte le funzionalit&agrave; del suo software del browser.</p>
                        <p>Per saperne di pi&ugrave; in merito ai cookie, ivi compreso come vedere le relative impostazioni e come gestirli e rimuoverli, si rimanda al sito http://www.allaboutcookies.org (il link &egrave; esterno).</p>
                        <p>In questo Sito web potremmo utilizzare tag di Internet (anche noti come web-beacon, action tag, single-pixel GIF, GIF trasparenti, GIF invisibili e GIF 1-by-1) e cookie, e potremmo farlo per mezzo di un partner di servizi analitici web avente sede e che memorizzi le rispettive informazioni (ivi compreso il Vostro indirizzo IP) in un paese estero. Questi tag/cookie sono collocati in pagine diverse di questo Sito web. Ci avvaliamo di questa tecnologia per misurare le risposte dell&rsquo;utente ai nostri siti (ivi compreso quante volte una pagina viene aperta e quali informazioni vengono consultate), nonch&eacute; per valutare l&rsquo;uso di questo Sito web.</p>
                        <p>I nostri partner terzi, provider di servizi di hosting e/o il partner di servizi analitici web potrebbero raccogliere dati in merito al Vostro uso di questo Sito web in virt&ugrave; di tali tag di Internet/cookie anonimizzati e potrebbero redigere report per noi in merito all&rsquo;attivit&agrave; di questo Sito web e prestare ulteriori servizi correlati all&rsquo;uso del Sito web e di Internet. Essi potrebbero fornire tali informazioni ad altre parti ove ci&ograve; fosse previsto ai sensi di legge o qualora si avvalessero di altre parti che trattino i dati per loro conto.</p>
                        <p>Questo Sito web utilizza Google Analytics, un servizio di analisi web di Google Inc. (&ldquo;Google&rdquo;). Google Analytics utilizza i cosiddetti &ldquo;cookie&rdquo; (<em>file</em> di testo memorizzati sul Vostro computer), che gli consentono di analizzare l&rsquo;uso che viene fatto del Sito web. Le informazioni prodotte dal cookie in merito al Vostro uso di questo Sito web vengono generalmente trasmesse a un server di Google negli Stati Uniti e l&igrave; memorizzate. In caso di attivazione della procedura di anonimizzazione dell&rsquo;IP su questo Sito web, il Vostro indirizzo IP sar&agrave; comunque abbreviato prima da Google in uno Stato Membro dell&rsquo;UE o in qualsiasi altro Stato che abbia sottoscritto l&rsquo;Accordo sullo Spazio Economico Europeo. Solo in casi eccezionali l&rsquo;indirizzo IP completo sar&agrave; trasmesso a un server di Google negli Stati Uniti e l&igrave; abbreviato. Se cedute dall&rsquo;operatore di questo Sito web, Google utilizzer&agrave; queste informazioni per valutare il Vostro uso del Sito web, al fine di compilare report sulle attivit&agrave; del Sito web per gli operatori dello stesso e prestare ulteriori servizi associati all&rsquo;uso del Sito web e di Internet. L&rsquo;indirizzo IP trasmesso nel modello di Google Analytics dal Vostro browser non sar&agrave; associato ad altri dati da Google. Voi potrete bloccare l&rsquo;installazione dei cookie impostando adeguatamente il software del browser; tuttavia, vorremmo sottolineare che in questo caso potreste non essere in grado di utilizzare appieno tutte le funzioni di questo Sito web.</p>
                        <p>Per maggiori informazioni sulle condizioni d&rsquo;uso e sulla protezione dei dati, si rimanda all&rsquo;indirizzo http://www.google.com/analytics/terms/en.html o all&rsquo;indirizzo <a href="https://www.google.co.uk/intl/en/policies/">https://www.google.co.uk/intl/en/policies/</a>; per le norme sulla privacy di Google, segnaliamo il seguente indirizzo http://www.google.com/intl/it/privacy/privacy-policy.html) (i link sono esterni). Vorremmo sottolineare che su questo Sito web, Google Analytics &egrave; stato esteso con il codice &ldquo;anonymizeIp&rdquo; per garantire la raccolta anonima degli indirizzi IP (cosiddetto IP masking).</p>
                        <p>&nbsp;</p>
                        <p><strong>Google Adwords &amp; Google Remarketing Cookies</strong></p>
                        <p>Il sito internet potrebbe utilizzare il programma Google Adwords e la tecnologia Google Remarketing. Entrambi sono gestiti da Google Inc.. Anche la funzione monitoraggio delle conversioni di AdWords utilizza i cookie. Per aiutarci a tenere traccia delle vendite e di altre conversioni, viene aggiunto un cookie al computer di un utente nel momento in cui quell&rsquo;utente fa clic su un annuncio. Questo cookie dura 30 giorni e non raccoglie, n&eacute; monitora informazioni in grado di identificare personalmente un utente. Gli utenti possono disabilitare i cookie del monitoraggio delle conversioni di Google nelle impostazioni del proprio browser Internet. In alcuni casi i cookie possono causare problemi al momento dell&rsquo;accesso o durante la navigazione all&rsquo;interno del tuo account AdWords. Quando ci&ograve; si verifica, il modo migliore per correggere il problema consiste nello svuotare la cache ed eliminare i cookie salvati per il tuo browser Internet. Per saperne di pi&ugrave; su&nbsp;<a href="https://support.google.com/adwords/topic/3121763?hl=it&amp;ref_topic=3119071" target="_blank" rel="noopener">clicca qui</a>&nbsp;&nbsp;<a href="https://www.google.es/intl/it/policies/privacy/" target="_blank" rel="noopener">https://www.google.es/intl/it/policies/privacy/</a>. L&rsquo;utente infine pu&ograve; disabilitare i cookies di Google Analytics scaricando uno specifico plug-in del browser reperibile al seguente url&nbsp;&nbsp;<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener">https://tools.google.com/dlpage/gaoptout</a>.</p>
                        <p>&nbsp;</p>
                        <p>Infine potremmo inoltre utilizzare le seguenti tipologie di cookie comuni:</p>
                        <ul>
                            <li>cookie utilizzati per personalizzare l&rsquo;interfaccia utente (ossia cookie di memorizzazione delle preferenze);</li>
                            <li>cookie di autenticazione (ossia cookie che Vi consentono di abbandonare i nostri siti e di ritornarvi senza dover ripetere l&rsquo;autenticazione);</li>
                            <li>cookie utilizzati per contenuti audio/video (ossia cookie che memorizzano i dati necessari per riascoltare un audio o rivedere un video e le Vostre preferenze);</li>
                            <li>cookie utilizzati per la profilazione, volti a creare profili relativi all'utente che vengono di norma utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso nell'ambito della navigazione in rete.</li>
                        </ul>
                        <p>Su questo sito sono utilizzati cookie tecnici e di profilazione, finalizzati a garantire la corretta fruizione dei contenuti del sito e per permettere la navigazione. In particolare il sito utilizza i cookies dei seguenti autori: Google Inc. (Analytics, ADWORDS, Remarketing, Youtube), Facebook Inc.. L&rsquo;utilizzo dei cookies avviene nel rispetto delle norme applicabili e, in particolare, del Regolamento UE 2016/679, fatta salva l&rsquo;eventuale responsabilit&agrave; degli autori degli stessi.</p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>Come disattivare i cookies</strong></p>
                        <p>Il sito web visitato consente di selezionare/deselezionare i singoli cookie, con le modalit&agrave; di seguito illustrate, ma si avverte che, in caso di disattivazione, potrebbe non essere garantita la completa fruibilit&agrave; del medesimo.</p>
                        <p><strong>Firefox:</strong></p>
                        <ul>
                            <li>apri Firefox;</li>
                            <li>premi il pulsante &ldquo;Alt&rdquo; sulla tastiera;</li>
                            <li>nella barra degli strumenti situata nella parte superiore del browser, seleziona &ldquo;Strumenti&rdquo; e successivamente &ldquo;Opzioni&rdquo;;</li>
                            <li>seleziona quindi la scheda &ldquo;Privacy&rdquo;;</li>
                            <li>vai su &ldquo;Impostazioni Cronologia:&rdquo; e successivamente su &ldquo;Utilizza impostazioni personalizzate&rdquo;. Deseleziona &ldquo;Accetta i cookie dai siti&rdquo; e salva le preferenze.</li>
                        </ul>
                        <p><strong>Internet Explorer:</strong></p>
                        <ul>
                            <li>apri Internet Explorer;</li>
                            <li>clicca sul pulsante &ldquo;Strumenti&rdquo; e quindi su &ldquo;Opzioni Internet&rdquo;;</li>
                            <li>seleziona la scheda &ldquo;Privacy&rdquo; e sposta il dispositivo di scorrimento sul livello di privacy che desideri impostare (verso l&rsquo;alto per bloccare tutti i cookies o verso il basso per consentirli tutti);</li>
                            <li>quindi clicca su OK.</li>
                        </ul>
                        <p><strong>Google Chrome:</strong></p>
                        <ul>
                            <li>apri Google Chrome;</li>
                            <li>clicca sull'icona &ldquo;Strumenti&rdquo;;</li>
                            <li>seleziona &ldquo;Impostazioni&rdquo; e successivamente &ldquo;Impostazioni avanzate&rdquo;;</li>
                            <li>seleziona &ldquo;Impostazioni dei contenuti&rdquo; sotto la voce &ldquo;Privacy&rdquo;;</li>
                            <li>nella scheda &ldquo;Cookies&rdquo; &egrave; possibile deselezionare i cookies e salvare le preferenze.</li>
                        </ul>
                        <p><strong>Safari:</strong></p>
                        <ul>
                            <li>apri Safari;</li>
                            <li>scegli &ldquo;Preferenze&rdquo; nella barra degli strumenti, quindi seleziona il pannello &ldquo;Sicurezza&rdquo; nella finestra di dialogo che segue;</li>
                            <li>nella sezione &ldquo;Accetta cookie&rdquo; &egrave; possibile specificare se e quando Safari deve salvare i cookies dai siti web. Per ulteriori informazioni clicca sul pulsante di Aiuto (contrassegnato da un punto interrogativo);</li>
                            <li>per maggiori informazioni sui cookies che vengono memorizzati sul vostro computer, clicca su &ldquo;Mostra cookie&rdquo;.</li>
                        </ul>
                        <p><a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener"><strong>Funzioni di disattivazione attualmente disponibili</strong></a><strong>&nbsp;per il Web di Google Analytics.</strong></p>
                        <p>Per offrire ai visitatori dei siti web la possibilit&agrave; di impedire l'utilizzo dei propri dati da parte di Google Analytics, &egrave; stato sviluppato il componente aggiuntivo del browser che disattiva il codice JavaScript di Google Analytics (ga.js, analytics.js, dc.js).</p>
                        <p>Se desideri disattivare Google Analytics, scarica e installa il componente aggiuntivo per il browser web in uso. Il componente aggiuntivo che disattiva Google Analytics &egrave; compatibile con Chrome, Internet Explorer 11, Safari, Firefox e Opera. Per funzionare, deve essere caricato ed eseguito correttamente nel browser in uso. Per Internet Explorer &egrave; necessario attivare i cookie di terze parti.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );

};